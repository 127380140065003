export const breakPoint = {
  mobile: 1200,
  tablet: 1440,
  desktop: 1920,
};

const color = {
  black_1: '#121212',
  white_1: '#FFFFFF',
  white_2: '#F1F1F1',
  grey_1: '#242424',
  grey_2: '#eeeeee',
  grey_3: '#dedede',
  grey_4: '#bdbdbd',
  grey_5: '#9d9d9d',
  red_1: '#FF9189',

  background: {
    black_1: '#121212',
    white_1: '#FFFFFF',
    grey_1: '#242424',
    grey_2: '#373737',
    grey_3: '#303030',
    grey_4: '#494949',
    grey_5: '#9d9d9d',
    red_1: '#FF766C',
    NAVER: '#1EC800',
    KAKAO: '#FEE500',
    GOOGLE: '#EEEEEE',
  },

  border: {
    grey_1: '#5b5b5b',
    grey_2: '#494949',
  },
};

const FONT_SIZE = {
  lg_3x: '24px',
  lg_2x: '22px',
  lg_x: '20px',
  lg_2: '18px',
  lg_1: '16px',
  md: '14px',
  sm: '12px',
  sm_x: '10px',
};
type TFontSize = keyof typeof FONT_SIZE;

const mixin = {
  convertMobileWidth: (property: any, pixel: string) => {
    if (Number.isNaN(Number(pixel))) {
      const valueArray = pixel.split(' ');
      return `${property}: ${valueArray
        .map((value) =>
          Number.isNaN(Number(value))
            ? value
            : `${100 * (Number(value) / breakPoint.mobile)}vw`,
        )
        .join(' ')}`;
    }
    return `${property}: ${100 * (Number(pixel) / breakPoint.mobile)}vw`;
  },
  convertDesktopWidth: (property: any, pixel: number) =>
    `${property}: ${100 * (pixel / breakPoint.desktop)}vw`,
};

export const theme = {
  color,
  mixin,
  breakPoint,
  FONT_SIZE,
};

export type { TFontSize };
export default theme;
