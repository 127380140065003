import { atomWithReset } from 'jotai/utils';

interface IAuthInfo {
  user: null | any;
  isVerification: null | boolean;
}
/**
 * 유저 로그인 정보
 */
export const authInfo = atomWithReset<IAuthInfo>({
  user: null,
  isVerification: null,
});
