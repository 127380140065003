import * as React from 'react';
import * as S from './styles';

export interface CommonButtonInterface
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  children?: any;
  onClick?: () => void;
}

function Button({
  children,
  type = 'button',
  onClick,
  ...rest
}: CommonButtonInterface) {
  return (
    <S.Button type={type} onClick={onClick} {...rest}>
      {children}
    </S.Button>
  );
}

export default Button;
