import styled, { css } from 'styled-components';

// Common Component
import CommonLink, { ICommonLink } from '@src/components/common/Link';
import CommonButton from '@src/components/common/Button';
import CommonInput from '@src/components/common/Input';

// styles
import { LinkButtonStyle } from '@src/components/common/Link/styles';
import {
  ISearchProps,
  ISideMenuProps,
  ISideMenuTriggerProps,
} from '@src/components/common/ResponsiveHeader/ResponsiveHeader';

export const ResponsiveHeader = styled.header`
  display: inline-flex;
  width: 100%;
  background-color: ${(props) => props.theme.color.background.white_1};
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
  }
`;
export const ResponsiveHeaderInner = styled.div`
  display: inline-flex;
  flex: 1 0 92px;
  height: 92px;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    position: sticky;
    z-index: 99;
    top: 0;
    left: 0;
    display: inline-flex;
    flex: 1 0 80px;
    height: 80px;
    width: 100%;
    ${(props) => props.theme.mixin.convertMobileWidth('padding-right', 20)};
    ${(props) => props.theme.mixin.convertMobileWidth('padding-left', 20)};
  }
`;

export const ResponsiveHeaderLeft = styled.div`
  display: inline-flex;
  align-items: center;
`;
export const ResponsiveHeaderRight = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    height: auto;
  }
`;

export const LogoLink = styled(CommonLink)`
  width: 150px;
`;
export const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
`;
// only Mobile
export const SideMenuTriggerItem = styled.div`
  transition: all 0.15s ease-out;
  height: 3px;
  width: 100%;
  background-color: ${(props) => props.theme.color.black_1};
`;
// only Mobile
export const SideMenuTrigger = styled(CommonButton)<ISideMenuTriggerProps>`
  display: none;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: content-box;
    width: 24px;
    height: 17px;
    padding: 5px;
    margin-right: 20px;

    ${SideMenuTriggerItem} {
      &:nth-child(2) {
        transform: scale(1);
      }
    }

    ${(props) =>
      props.active &&
      css`
        ${SideMenuTriggerItem} {
          &:nth-child(1) {
            transform: translateY(7px) rotate(45deg);
          }
          &:nth-child(2) {
            transform: scale(0);
          }
          &:nth-child(3) {
            transform: translateY(-7px) rotate(-45deg);
          }
        }
      `}
  }
`;

export const Menu = styled.div<ISideMenuProps>`
  display: inline-flex;
  margin-right: 32px;
  height: 100%;
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 1px;
    display: ${(props) => (props.active ? 'inline-flex' : 'none')};
    flex-direction: column;
    transform: translateY(100%);
    padding: 36px 32px;
    width: 100%;
    height: auto;
    margin-right: 0;
    background-color: ${(props) => props.theme.color.background.white_1};
    box-sizing: border-box;
  }
`;

interface IMenuItem {
  active?: boolean;
}
export const MenuItem = styled(CommonLink).withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<IMenuItem>`
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0 28px;
  font-weight: 700;

  & + & {
    margin-left: 15px;
  }

  ${(props) =>
    props.active &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        height: 5px;
        width: 100%;
        background-color: ${props.theme.color.background.black_1}};
      }
    `}

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    padding: 12px 0;
    & + & {
      margin-left: 0;
    }

    &::after {
      content: none;
    }
  }
`;
export const SearchCloseButton = styled(CommonButton)`
  display: none;
  padding: 5px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
  }
`;
export const SearchCloseButtonImage = styled.img`
  max-width: 100%;
  height: auto;
`;
export const Search = styled.div<ISearchProps>`
  display: inline-flex;
  margin-left: 32px;
  width: 356px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: ${(props) => props.theme.color.background.white_1};
    display: ${(props) => (props.active ? 'inline-flex' : 'none')};

    ${SearchCloseButton} {
      display: inline-block;
    }
  }
`;

export const SearchForm = styled.form`
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
`;

export const SearchInput = styled(CommonInput)`
  height: 40px;
  border-radius: 20px;
  padding: 0 54px 0 24px;
  border: 1px solid ${(props) => props.theme.color.black_1};
  font-size: ${(props) => props.theme.FONT_SIZE.md};
  font-weight: 500;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    border: none;
    border-radius: 0;
    height: 100%;
    padding: 0 124px 0 24px;
  }

  &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::placeholder {
    /* Edge */
    font-size: ${(props) => props.theme.FONT_SIZE.md};
  }
`;
export const SearchExecuteButton = styled(CommonButton)`
  padding: 5px;
  width: 18px;
  height: 18px;
  box-sizing: content-box;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    width: 20px;
    height: 20px;
    margin-right: 22px;
  }
`;
export const SearchExecuteButtonImage = styled.img`
  max-width: 100%;
  height: auto;
`;

export const MobSearchBarTriggerButton = styled(CommonButton)`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    padding: 5px;
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
`;
export const LoginLink = styled(CommonButton)`
  ${LinkButtonStyle()}

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    padding: 8px 16px;
  }
`;

export const SearchBarButtons = styled.div`
  position: absolute;
  display: inline-flex;
  right: 20px;
  align-items: center;
`;
export const SearchSideMenu = styled.div`
  position: absolute;
  display: inline-flex;
  right: 20px;
  align-items: center;
`;

export const HeaderProfileWrap = styled(CommonLink)`
  display: inline-flex;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  overflow: hidden;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    width: 32px;
    height: 32px;
  }
`;
export const HeaderProfileImage = styled.img`
  width: 100%;
  height: 100%;
`;
