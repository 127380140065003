import { Suspense, useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// common components
import ResponsiveHeader from '@src/components/common/ResponsiveHeader';
import ResponsiveFooter from '@src/components/common/ResponsiveFooter';
import CommonModal from '@src/components/common/Modal';
import Toast from '@src/components/common/Toast';

// styles
import * as S from '@src/components/common/Layout/styles';

interface ICommonLayout {
  children: React.ReactNode;
}
export default function CommonLayout({ children }: ICommonLayout) {
  const appRef = useRef();
  const layoutRef = useRef<any>(null);
  const location = useLocation();

  useLayoutEffect(() => {
    layoutRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <>
      <S.LayoutWrapper ref={layoutRef}>
        <ResponsiveHeader />
        <S.Container className="CommonLayout__Container">
          {children}
        </S.Container>
        <ResponsiveFooter />
        <Toast
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Suspense fallback={null}>
          <CommonModal container={appRef} />
        </Suspense>
      </S.LayoutWrapper>
    </>
  );
}
