import axios from 'axios';
import appLocalStorage from '@src/lib/storage/localStorage';

let headers = null;
const AUTH_KEY = 'nabba_korea.auth';
const controller = new AbortController();

// api 기본 인증값 주입
try {
  const auth = appLocalStorage.get(AUTH_KEY);
  if (auth) {
    if (auth.accessToken)
      headers = { Authorization: `Bearer ${auth.accessToken}` };
    if (auth.refreshToken) headers.refreshToken = auth.refreshToken;
  }
} catch (e) {
  console.error(e);
}

export const request = axios.create({
  timeout: 10000,
  baseURL: process.env.REACT_APP_CCCV_API_URL,
  headers,
  validateStatus: (status) => {
    return (status >= 200 && status < 300) || status === 401;
  },
  signal: controller.signal,
});

// axios response interceptors
request.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      appLocalStorage.clean([AUTH_KEY]);
      controller.abort();
      if (
        !/\/login\S*/g.test(window.location.pathname) &&
        !/\/nft\/my\S*/g.test(window.location.pathname)
      ) {
        alert('로그인이 만료됐습니다.');
        window.location.reload();
      }
    }

    return response;
  },
  (err) => {
    // Axios요청을 Abort하는 경우를 제외하면 Error throw
    if (!axios.isCancel(err)) {
      // axios 공통 에러처리
      // axios 인스턴스 생성 시, flag로 toast UI 노출 여부 체크
      throw err?.response?.data;
    }
  },
);

// axios reqeust interceptors
request.interceptors.request.use((config) => {
  const _config = config;

  const auth = appLocalStorage.get(AUTH_KEY);
  if (auth) {
    if (auth.accessToken)
      headers = { Authorization: `Bearer ${auth.accessToken}` };
    if (auth.refreshToken) headers.refreshToken = auth.refreshToken;
    _config.headers = headers;
  } else {
    _config.headers = null;
  }

  return _config;
});

export const getMyPageInfo = () => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/pages/myPage`);
};

export const getMyVerifications = () => {
  return request.get(
    `${process.env.REACT_APP_CCCV_API_URL}/verifications/myVerifications`,
  );
};

export const checkTokenStatus = () => {
  return request.get(`${process.env.REACT_APP_CCCV_API_URL}/users/whoami`);
};
