export const CCCV_ROUTE = {
  main: '/nft',
  mycccv: '/set',
  mint: '/nft/mint',
  pay: '/nft/#{id}/pay', // 일반 구매
  bid: '/nft/#{id}/bid', // 입찰
  offer: '/nft/#{id}/offer', // 제안
  download: '/nft/user/#{pageId}/his/download',
  usageHistory: '/nft/user/#{pageId}/his',
  paymentHistory: '/nft/user/#{pageId}/settle/list',
  myBadge: '/set/badges',
  editProfile: '/nft/user/#{pageId}',
  sellNFT: '/nft/#{tokenId}/sell',
  qr: '/qr',
};

export const NABBA_KOREA_ROUTE = {
  main: '/',
  login: {
    default: '/login',
  },
  awards: {
    default: '/awards',
    auth_badge: '/auth_badge',
  },
  player: {
    default: '/player',
  },
  competition: {
    default: '/competition',
  },
  privacy: {
    default: '/privacy',
  },
  terms: {
    default: '/terms',
  },
  mypage: {
    default: '/mypage',
    pageId: ':pageId',
  },
  search: {
    default: '/search',
  },
  kakaoAuth: '/_o/kakao',
  naverAuth: '/_o/naver',
  googleAuth: '/_o/google',
  errorPage: '*',
};
