import { ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import * as S from './styles';

export interface CommonToastInterface extends ToastContainerProps {
  children?: any;
}

function Toast({ children, ...rest }: CommonToastInterface) {
  return <S.CommonToastContainer {...rest} />;
}

export default Toast;
