import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ICommonLink } from '@src/components/common/Link';

export const Link = styled(RouterLink)<ICommonLink>``;

export const LinkButtonStyle = () => css`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  color: ${(props) => props.theme.color.white_1};
  background-color: ${(props) => props.theme.color.black_1};
  height: 44px;
  border-radius: 22px;
  font-weight: 500;
  box-sizing: border-box;

  &:hover {
    color: ${(props) => props.theme.color.white_1};
  }
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    height: 28px;
    font-size: ${(props) => props.theme.FONT_SIZE.sm};
  }
`;
