import { useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { useLocation, matchRoutes, useNavigate } from 'react-router-dom';
import qs from 'qs';

// atoms
import { authInfo as authInfoAtom } from '@src/atoms/user';

// config
import { NABBA_KOREA_ROUTE } from '@src/config/staticRouteConfig';

// images
import HeaderLogo from '@src/images/common/header_logo.svg';
import SearchIcon from '@src/images/header/search-icon.svg';
import SearchCloseIcon from '@src/images/header/mob-search-close-icon.svg';

// validation
import { isOnlyWhiteSpaceCheck } from '@src/utils/validation';

// styles
import * as S from '@src/components/common/ResponsiveHeader/styles';

export interface ISearchProps {
  active: boolean;
}
export interface ISideMenuProps {
  active: boolean;
}

export interface ISideMenuTriggerProps {
  active: boolean;
}

export default function ResponsiveHeader() {
  const navigate = useNavigate();
  const location = useLocation();
  const [authInfo, setAuthInfo] = useAtom(authInfoAtom);
  const [mobileSearchBarActive, setMobileSearchBarActive] =
    useState<boolean>(false);
  const [mobileSideMenuActive, setMobileSideMenuActive] =
    useState<boolean>(false);
  const searchBarInput = useRef<any>(null);

  //
  const onSearchHandler = () => {
    // 공백 및 빈값 체크
    if (searchBarInput.current) {
      const searchBarInputValue = searchBarInput.current.getValue();

      // 검색바 리셋
      searchBarInput.current.reset();
      if (!isOnlyWhiteSpaceCheck(searchBarInputValue)) {
        navigate({
          pathname: '/search',
          search: `?keyword=${encodeURIComponent(searchBarInputValue)}`,
        });
      }
    }
  };

  /**
   * CCCV Login 이동
   */
  const signinWidthRedirectByCCCV = () => {
    const queryStr = qs.stringify(
      {
        // 로고 이미지 주소! 변경해도 됩니다.
        logo: `${process.env.REACT_APP_URL}/static/media/header_logo.5a2afa092192eb7dfd461d4a7b6b4f8e.svg`,
        // cccv에서 로그인 후 토큰을 들고 갈 nabba 도메인, 일단 login으로 했음
        redirectUrl: `${process.env.REACT_APP_URL}/_o/google`,
        // 문구 1
        text1: '역대 나바코리아 아카이빙을 확인하고',
        // 문구 2
        text2: '수상 인증을 통해 퍼스널브랜딩을 완성하세요',
        joinRoute: 'nabba',
        'authRoles[]': ['nabba'],
      },
      {
        arrayFormat: 'repeat',
      },
    );

    window.location.href = `${process.env.REACT_APP_CCCV_URL}/join/external?${queryStr}`;
  };

  /**
   * AuthInfo가 있을 경우, 유저 이미지 노출
   */
  const HeaderProfile = () => {
    return authInfo?.user ? (
      <>
        <S.HeaderProfileWrap
          to={`${NABBA_KOREA_ROUTE.mypage.default}${
            authInfo?.user.pageId ? `/${authInfo?.user.pageId}` : ''
          }`}
        >
          <S.HeaderProfileImage src={authInfo?.user?.profileImgUrl} />
        </S.HeaderProfileWrap>
      </>
    ) : (
      <>
        <S.LoginLink
          className="ResponsiveHeader__LoginButton"
          onClick={signinWidthRedirectByCCCV}
        >
          로그인
        </S.LoginLink>
      </>
    );
  };

  return (
    <>
      <S.ResponsiveHeader className="ResponsiveHeader">
        <S.ResponsiveHeaderInner>
          <S.ResponsiveHeaderLeft>
            <S.SideMenuTrigger
              className="ResponsiveHeader__SideMenuTrigger"
              active={mobileSideMenuActive}
              onClick={() => setMobileSideMenuActive(!mobileSideMenuActive)}
            >
              <S.SideMenuTriggerItem className="ResponsiveHeader__SideMenuTriggerItem" />
              <S.SideMenuTriggerItem className="ResponsiveHeader__SideMenuTriggerItem" />
              <S.SideMenuTriggerItem className="ResponsiveHeader__SideMenuTriggerItem" />
            </S.SideMenuTrigger>
            <S.LogoLink
              to={NABBA_KOREA_ROUTE.main}
              className="ResponsiveHeader__Logo"
            >
              <S.LogoImage src={HeaderLogo} alt="NABBA KOREA NFT 로고" />
            </S.LogoLink>
            <S.Search
              className="ResponsiveHeader__Search"
              active={mobileSearchBarActive}
            >
              <S.SearchForm
                className="ResponsiveHeader__SearchForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSearchHandler();
                }}
              >
                <S.SearchInput
                  ref={searchBarInput}
                  placeholder="찾으시는 선수 또는 인증서를 검색해 보세요."
                  type="text"
                />
                <S.SearchBarButtons>
                  <S.SearchExecuteButton
                    className="ResponsiveHeader__SearchExecuteButton"
                    type="submit"
                  >
                    <S.SearchExecuteButtonImage
                      src={SearchIcon}
                      alt="검색 실행버튼 이미지"
                    />
                  </S.SearchExecuteButton>
                  <S.SearchCloseButton
                    className="ResponsiveHeader__SearchCloseButton"
                    onClick={() => {
                      setMobileSearchBarActive(!mobileSearchBarActive);
                    }}
                  >
                    <S.SearchCloseButtonImage
                      src={SearchCloseIcon}
                      alt="검색 실행버튼 이미지"
                    />
                  </S.SearchCloseButton>
                </S.SearchBarButtons>
              </S.SearchForm>
            </S.Search>
          </S.ResponsiveHeaderLeft>
          <S.ResponsiveHeaderRight>
            {/* only PC */}
            <S.Menu
              className="ResponsiveHeader__Menu"
              active={mobileSideMenuActive}
            >
              <S.MenuItem
                to={NABBA_KOREA_ROUTE.player.default}
                className="ResponsiveHeader__MenuItem"
                active={
                  matchRoutes(
                    [{ path: `${NABBA_KOREA_ROUTE.player.default}/*` }],
                    location,
                  ) !== null
                }
              >
                선수
              </S.MenuItem>
              <S.MenuItem
                to={NABBA_KOREA_ROUTE.competition.default}
                className="ResponsiveHeader__MenuItem"
                active={
                  matchRoutes(
                    [{ path: `${NABBA_KOREA_ROUTE.competition.default}/*` }],
                    location,
                  ) !== null
                }
              >
                대회
              </S.MenuItem>
              <S.MenuItem
                to={NABBA_KOREA_ROUTE.awards.default}
                className="ResponsiveHeader__MenuItem"
                active={
                  matchRoutes(
                    [
                      { path: `${NABBA_KOREA_ROUTE.awards.default}/*` },
                      { path: `${NABBA_KOREA_ROUTE.awards.default}` },
                    ],
                    location,
                  ) !== null
                }
              >
                수상인증
              </S.MenuItem>
            </S.Menu>
            {/* !only PC */}
            {/* only Mob */}
            <S.MobSearchBarTriggerButton
              onClick={() => {
                setMobileSearchBarActive(!mobileSearchBarActive);
              }}
            >
              <S.SearchExecuteButtonImage
                src={SearchIcon}
                alt="모바일 검색바 활성화"
              />
            </S.MobSearchBarTriggerButton>
            {/* !only Mob */}
            {HeaderProfile()}
          </S.ResponsiveHeaderRight>
        </S.ResponsiveHeaderInner>
      </S.ResponsiveHeader>
    </>
  );
}
