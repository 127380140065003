import styled, { css } from 'styled-components';

// Common Component
import CommonLink from '@src/components/common/Link';

export const ResponsiveFooter = styled.footer`
  display: inline-flex;
  width: 100%;
  padding: 65px 0;
  background-color: ${(props) => props.theme.color.background.black_1};
  justify-content: center;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    padding: 48px 0;
  }
`;

export const ResponsiveFooterInner = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  color: ${(props) => props.theme.color.white_1};
  font-size: ${(props) => props.theme.FONT_SIZE.sm};

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    padding: 0 30px;
  }
`;

export const LogoWrap = styled.div`
  display: inline-flex;
`;

export const LogoLink = styled.a`
  display: inline-block;
  width: 185px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    width: 130px;
  }
`;
export const LogoImage = styled.img`
  max-width: 100%;
  height: auto;
`;
export const CCCVLogoLink = styled.a`
  display: inline-block;
  width: 160px;
  margin-left: 40px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    width: 112px;
    margin-left: 16px;
  }
`;
export const CCCVLogoImage = styled.img`
  max-width: 100%;
  height: auto;
`;
export const SiteLink = styled.div`
  font-weight: bold;
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    margin-top: 32px;
  }
`;
export const SiteLinkItem = styled(CommonLink)`
  position: relative;
  & + & {
    margin-left: 5px;
    padding-left: 6px;
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-block;
      height: 12px;
      width: 2px;
      background-color: ${(props) => props.theme.color.white_1};
    }
  }

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    margin-top: 32px;
  }
`;
export const Description = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 100%;
  flex: 2 0 0;
`;
export const DescriptionItem = styled.div`
  position: relative;
  display: inline-flex;
  & + & {
    margin-left: 5px;
    &::before {
      content: '|';
      display: inline-block;
      padding-right: 5px;
    }
  }
`;
export const CopyRightWrap = styled.div`
  display: inline-flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex: 1 0 0;
  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    margin-top: 32px;
  }
`;
export const CopyRightItem = styled.span`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
  }
`;
export const ResponsiveFooterTop = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
export const ResponsiveFooterBottom = styled.div`
  display: inline-flex;
  width: 100%;
  align-items: flex-end;
  align-content: flex-start;
  margin-top: 55px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    flex-direction: column;
    margin-top: 32px;
  }
`;
