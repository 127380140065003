import { Suspense, lazy, useEffect } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { ThemeProvider } from 'styled-components';

// Layout
import CommonLayout from '@src/components/common/Layout';

// config
import { NABBA_KOREA_ROUTE } from '@src/config/staticRouteConfig';
import { theme } from '@src/config/styled-components/variable';

// atom
import {
  asyncSetAuthInfoUser as actionAuthInfoUser,
  asyncSetAuthInfoIsVerification as actionAuthInfoIsVerification,
} from '@src/atoms/user';

// css
import './App.css';

declare global {
  interface Window {
    // kakao 라이브러리 typescript 글로벌 선언
    Kakao: any;
  }
}

const MainRouterComponent = lazy(() => import('@src/routes/Main'));
const NotFoundRouterComponent = lazy(() => import('@src/routes/NotFound'));
const AwardsRouterComponent = lazy(() => import('@src/routes/Awards'));
const AwardsAuthRouterComponent = lazy(() => import('@src/routes/Awards/Auth'));
const PlayerRouterComponent = lazy(() => import('@src/routes/Player'));
const CompetitionRouterComponent = lazy(
  () => import('@src/routes/Competition'),
);
const SearchRouterComponent = lazy(() => import('@src/routes/Search'));
const MypageRouterComponent = lazy(() => import('@src/routes/Mypage'));
const GoogleAuthRouterComponent = lazy(() => import('@src/routes/GoogleAuth'));
const PrivacyRouterComponent = lazy(() => import('@src/routes/Privacy'));
const TermsRouterComponent = lazy(() => import('@src/routes/Terms'));

const App = () => {
  const setAuthInfoUser = useSetAtom(actionAuthInfoUser);
  const setAuthInfoIsVerification = useSetAtom(actionAuthInfoIsVerification);

  useEffect(() => {
    const { Kakao } = window;
    if (Kakao && !Kakao.isInitialized())
      Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_API_KEY);

    // User Info Initialize
    setAuthInfoUser();
    setAuthInfoIsVerification();
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CommonLayout>
          {/* fallback 로딩으로 대체 */}
          <Suspense fallback={<div>fallback...</div>}>
            <Routes>
              <Route path="/" element={<MainRouterComponent />} />
              <Route
                path={NABBA_KOREA_ROUTE.awards.default}
                element={<AwardsRouterComponent />}
              />
              <Route
                path={`${NABBA_KOREA_ROUTE.awards.default}${NABBA_KOREA_ROUTE.awards.auth_badge}`}
                element={<AwardsAuthRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.player.default}
                element={<PlayerRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.competition.default}
                element={<CompetitionRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.search.default}
                element={<SearchRouterComponent />}
              />
              <Route path={NABBA_KOREA_ROUTE.mypage.default}>
                <Route index element={<MypageRouterComponent />} />
                <Route
                  path={NABBA_KOREA_ROUTE.mypage.pageId}
                  element={<MypageRouterComponent />}
                />
              </Route>
              {/* <Route
                path={NABBA_KOREA_ROUTE.kakaoAuth}
                element={<KakaoAuthRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.naverAuth}
                element={<NaverAuthRouterComponent />}
              /> */}
              <Route
                path={NABBA_KOREA_ROUTE.googleAuth}
                element={<GoogleAuthRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.privacy.default}
                element={<PrivacyRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.terms.default}
                element={<TermsRouterComponent />}
              />
              <Route
                path={NABBA_KOREA_ROUTE.errorPage}
                element={<NotFoundRouterComponent />}
              />
            </Routes>
          </Suspense>
        </CommonLayout>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
