import { atom } from 'jotai';
import { authInfo } from '@src/atoms/user';

// storage
import appLocalStorage from '@src/lib/storage/localStorage';
import { AUTH_KEY } from '@src/common/apiFactory';

// api
import * as api from '@src/common/api';

export const asyncSetAuthInfoUser = atom(null, async (get, set) => {
  // user 정보 조회
  let _user: unknown = null;
  const storageAuthkey = appLocalStorage.get(AUTH_KEY);

  if (storageAuthkey?.accessToken && storageAuthkey?.refreshToken) {
    api
      .getMyPageInfo()
      .then((res: any) => {
        if (res?.data?.error !== 'Unauthorized' || res.status !== 401) {
          _user = res?.data?.data;
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        set(authInfo, { ...get(authInfo), user: _user });
      });
  } else {
    appLocalStorage.clean([AUTH_KEY]);
    set(authInfo, { ...get(authInfo), user: null });
  }
});

export const asyncSetAuthInfoIsVerification = atom(null, async (get, set) => {
  // _isVerification 정보 확인
  let _isVerification: null | boolean = null;
  const storageAuthkey = appLocalStorage.get(AUTH_KEY);

  if (storageAuthkey?.accessToken && storageAuthkey?.refreshToken) {
    api
      .getMyVerifications()
      .then((res: any) => {
        _isVerification = !!res?.data?.data;
      })
      .catch((_err) => {
        console.error(_err);
      })
      .finally(() => {
        set(authInfo, { ...get(authInfo), isVerification: _isVerification });
      });
  } else {
    appLocalStorage.clean([AUTH_KEY]);
    set(authInfo, { ...get(authInfo), isVerification: null });
  }
});
