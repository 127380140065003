import { atomWithReducer } from 'jotai/utils';

interface IModalAtom {
  ModalComponentName?: string;
  ModalComponent: any; // 값 추가필요
  props?: any;
}

interface IModalAtomAction extends IModalAtom {
  type: 'OPEN' | 'CLOSE';
}

const modalListReducer = (
  prev: IModalAtom[],
  action: IModalAtomAction,
): IModalAtom[] => {
  const { ModalComponentName, ModalComponent, props, type } = action;
  switch (type) {
    case 'OPEN':
      return [
        ...prev,
        {
          ModalComponentName,
          ModalComponent,
          props,
        },
      ];
    case 'CLOSE':
      return [
        ...prev.filter((modal) => modal.ModalComponent !== ModalComponent),
      ];
    default:
      return prev;
  }
};

const modalListAtom = atomWithReducer<IModalAtom[], any>([], modalListReducer);

export { modalListAtom };
