import React, { useImperativeHandle } from 'react';
import useInputs, { ActionTypes } from '@src/hooks/useInputs';

// styles
import * as S from '@src/components/common/Input/styles';

export interface ICommonInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  ref?: React.Ref<unknown>;
  inputType?: ActionTypes;
}

function Input({ ref, inputType, ...rest }: ICommonInputProps) {
  const { useInputValue, useInputOnChange, useInputReset } = useInputs(
    '',
    inputType,
  );
  useImperativeHandle(
    ref,
    () => ({
      getValue: () => useInputValue,
      reset: () => {
        useInputReset();
      },
    }),
    [useInputValue],
  );
  return (
    <>
      <S.Input
        onChange={(e) => useInputOnChange(e)}
        value={useInputValue}
        {...rest}
      />
    </>
  );
}

// for display-name warning
const CommonInput = React.forwardRef<HTMLInputElement, ICommonInputProps>(
  (props: ICommonInputProps, ref) => {
    const _Input = Input({ ...props, ref });
    return _Input;
  },
);
CommonInput.displayName = 'Input';
export default CommonInput;
