interface ISetParams {
  [key: string]: unknown;
}

const appLocalStorage = {
  get(key: string) {
    const getData = localStorage.getItem(key);

    return getData ? JSON.parse(getData) : null;
  },
  set(paramsObj: ISetParams) {
    Object.keys(paramsObj).forEach((key) => {
      localStorage.setItem(key, JSON.stringify(paramsObj[key]));
    });
  },
  clean(array: string[]) {
    const _array = array;
    _array.forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};

export default appLocalStorage;
