import * as React from 'react';
import { LinkProps } from 'react-router-dom';

// styles
import * as S from './styles';

export interface ICommonLink
  extends LinkProps,
    React.RefAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

function CommonLink({ children, ...rest }: ICommonLink) {
  return <S.Link {...rest}>{children}</S.Link>;
}

export default CommonLink;
