export const INFO = {
  name: {
    key: '',
    value: '(주)블로코엑스와이지',
  },
  owner: {
    key: '대표이사',
    value: '김경훈',
  },
  companyRegistrationNumber: {
    key: '사업자등록번호',
    value: '591-81-00941',
  },
  representativeEmail: {
    key: '대표 이메일',
    value: 'cccv@cccv.to',
  },
  representativePhone: {
    key: '대표전화',
    value: '031-8022-6264',
  },
  address: {
    key: '주소',
    value: '부산광역시 남구 문현금융로 40, 9층 10호(역외기업육성센터)',
  },
};

export const RIGHT_INFO = {
  copyRight: 'Copyright © 나바아시아 Inc. All rights reserved.',
  poweredBy: 'Powered by © BLOCKO XYZ Inc. All rights reserved.',
};
