import styled from 'styled-components';

export const Container = styled.main`
  width: 100%;
  flex: 1;
  background-color: ${(props) => props.theme.color.background.grey_1};
  padding-bottom: 80px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    padding-bottom: 48px;
  }
`;

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100vw;
  overflow: auto;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
    margin: 0 auto;
    max-width: 420px;
    box-shadow: 0 4px 10px 0 rgb(39 56 85 / 20%), inset 2px 2px 0 0 #fff;
  }
`;
