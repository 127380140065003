import styled, { css } from 'styled-components';
import { CommonButtonInterface } from './Button';

export const Button = styled.button<CommonButtonInterface>`
  display: inline-flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const defaultButtonStyle = (options: any) => css`
  justify-content: center;
  align-items: center;
  padding: 12px 24px;

  @media screen and (max-width: ${(props) => props.theme.breakPoint.mobile}px) {
  }
`;
