import * as React from 'react';
import { lazy, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Jotai Atom
import { useAtomValue } from 'jotai';
import { modalListAtom } from '@src/atoms/modal';
// import appLocalStorage from '@src/lib/storage/localStorage';
export interface IModalState {
  ModalComponentName: string;
  ModalComponent: React.LazyExoticComponent<any>;
  props: any;
}

// 모달 정의
export const modalList = {
  BadgeAuthModal: lazy(
    () => import('@src/components/common/Modals/BadgeAuthModal'),
  ),
  BadgeInfoModal: lazy(
    () => import('@src/components/common/Modals/BadgeInfoModal'),
  ),
};

const exceptedRouterRegex = [/\/login\S*/g, /\/_o\S*/g];

function Modal({ container }: any) {
  const routerLocation = useLocation();
  const [isBlockedModal, setIsBlockedModal] = useState<boolean>(true);

  const commonModals = useAtomValue(modalListAtom);

  useEffect(() => {
    // 모달 블로킹 라우터
    const isBlocked = exceptedRouterRegex.some((regex) =>
      regex.test(routerLocation.pathname),
    );
    setIsBlockedModal(isBlocked);

    // 모달 재렌더링 날짜
    // const InitModalExpireDate = appLocalStorage.get(
    //   'initModalHistoryExpireDate',
    // );
    // if (
    //   new Date().getTime() >= InitModalExpireDate ||
    //   InitModalExpireDate === null
    // ) {
    //   openModal('PageInitModal', modalList.pageInitModal, {
    //     show: true,
    //     onHide: () => closeModal(modalList.pageInitModal),
    //     onClickBlockExposureForOneDay: () => {
    //       appLocalStorage.set({
    //         initModalHistoryExpireDate: new Date().getTime() + 1000 * 60 * 24,
    //       });
    //       closeModal(modalList.pageInitModal);
    //     },
    //   });
    // }
  }, []);
  if (isBlockedModal) return null;

  return (
    <>
      {commonModals.map((modalObj) => {
        const { ModalComponentName, ModalComponent, props } = modalObj;
        const Component = ModalComponent;

        return (
          <Component
            key={ModalComponentName}
            container={container?.current}
            {...props}
          />
        );
      })}
    </>
  );
}

export default Modal;
