// common component
import CommonLink from '@src/components/common/Link';

// config
import { NABBA_KOREA_ROUTE, CCCV_ROUTE } from '@src/config/staticRouteConfig';
import { INFO, RIGHT_INFO } from '@src/config/info';

// images
import footerCCCVLogo from '@src/images/footer/footer_cccv_logo.svg';
import footerNABBALogo from '@src/images/footer/footer_logo.svg';

// styles
import * as S from '@src/components/common/ResponsiveFooter/styles';

interface IInfoItem {
  key: string;
  value: string;
}

export default function ResponsiveFooter() {
  return (
    <>
      <S.ResponsiveFooter>
        <S.ResponsiveFooterInner>
          <S.ResponsiveFooterTop>
            <S.LogoWrap>
              <S.LogoLink
                href={`${process.env.REACT_APP_NABBA_URL}`}
                target="_blank"
                referrerPolicy="no-referrer"
                className="ResponsiveFooter__Logo"
              >
                <S.LogoImage src={footerNABBALogo} alt="NABBA KOREA NFT 로고" />
              </S.LogoLink>
              <S.CCCVLogoLink
                href={`${process.env.REACT_APP_CCCV_URL}${CCCV_ROUTE.main}`}
                target="_blank"
                referrerPolicy="no-referrer"
                className="ResponsiveFooter__Logo ResponsiveFooter__Logo--CCCV"
              >
                <S.CCCVLogoImage src={footerCCCVLogo} alt="CCCV NFT 로고" />
              </S.CCCVLogoLink>
            </S.LogoWrap>
            <S.SiteLink className="ResponsiveFooter__Link">
              <S.SiteLinkItem
                to={NABBA_KOREA_ROUTE.terms.default}
                className="ResponsiveFooter__LinkItem"
              >
                서비스 이용약관
              </S.SiteLinkItem>
              <S.SiteLinkItem
                to={NABBA_KOREA_ROUTE.privacy.default}
                className="ResponsiveFooter__LinkItem"
              >
                개인정보처리방침
              </S.SiteLinkItem>
            </S.SiteLink>
          </S.ResponsiveFooterTop>
          <S.ResponsiveFooterBottom className="ResponsiveFooter__ResponsiveFooterBottom">
            <S.Description className="ResponsiveFooter__Description">
              {Object.values(INFO).map((infoItem: IInfoItem) => {
                return (
                  <S.DescriptionItem key={infoItem.key}>
                    {infoItem.key ? `${infoItem.key} : ` : ''}
                    {infoItem.value}
                  </S.DescriptionItem>
                );
              })}
            </S.Description>
            <S.CopyRightWrap className="ResponsiveFooter__CopyRightWrap">
              <S.CopyRightItem className="ResponsiveFooter__CopyRight">
                {RIGHT_INFO.copyRight}
              </S.CopyRightItem>
              <S.CopyRightItem className="ResponsiveFooter__PoweredBy">
                {RIGHT_INFO.poweredBy}
              </S.CopyRightItem>
            </S.CopyRightWrap>
          </S.ResponsiveFooterBottom>
        </S.ResponsiveFooterInner>
      </S.ResponsiveFooter>
    </>
  );
}
